import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import { breakpoints, flex } from '../../utils/style';
import ProfilePanel from '../../components/Profile';
import InfoPanel from '../../components/Profile/InfoPanel/index';
import { StoreContext } from '../../store';
import dictionary from '../../utils/dictionary';
import MobileMenu from '../../components/Profile/MobileMenu';
import { setProfileMenu } from '../../store/actions';

const Wrapper = styled('div')`
  & .profile-container {
    ${flex};
    justify-content: space-between;
    align-items: flex-start;
  }
  
  @media (max-width: ${breakpoints.md}) {
    & .profile-container {
      display: block;
    }
  }
`;

const Profile = ({ data, pageContext }) => {
  const [state, dispatch] = useContext(StoreContext);
  const {
    user: { userName },
    profile: { activeTab },
  } = state;

  useEffect(() => {
    if (!activeTab) setProfileMenu(dispatch, 'bets');
  }, []);

  const currentPage = data.wordpressPage;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : `${currentPage.title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const { breadcrumbs } = pageContext;

  return (
    <Layout>
      <SEO title={metaTitle} description={metaDesc} pathname={currentPage.path} breadcrumbs={breadcrumbs} />
      <Wrapper>
        {
          userName ? (
            <div className="profile-container">
              <ProfilePanel />
              <MobileMenu />
              <InfoPanel />
            </div>
          ) : (
            <h1>{dictionary.notLoggedInTitle}</h1>
          )
        }
      </Wrapper>
    </Layout>
  );
};

export default Profile;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`;
